import { Skeleton } from "../Skeleton";
import "./styles.scss";

export const QREditorSkeleton = () => {
  return (
    <div className="qr-editor-skeleton__container">
      <div className="qr-editor-skeleton__first-column">
        <div className="qr-editor-skeleton__qr-selector">
          {[...Array(10)].map((_, i) => (
            <div
              className="qr-editor-skeleton__qr-button"
              key={i}>
              <Skeleton className="qr-editor-skeleton__qr-button__icon" />
              <Skeleton
                width="100%"
                height="20px"
              />
            </div>
          ))}
        </div>
        <div className="qr-editor-skeleton__form">
          <div className="qr-editor-skeleton__input__container">
            <Skeleton
              width="110px"
              height="20px"
            />
            <Skeleton
              width="100%"
              height="48px"
            />
          </div>
          <div className="qr-editor-skeleton__accordion__container">
            <Skeleton
              width="100%"
              height="58px"
            />
            <Skeleton
              width="100%"
              height="58px"
            />
          </div>
        </div>
      </div>
      <div className="qr-editor-skeleton__second-column">
        <div className="qr-editor-skeleton__render-qr" />
        <Skeleton
          className="qr-editor-skeleton__button"
          backgroundColor="#d9d9d9"
        />
      </div>
    </div>
  );
};
