import React from "react";
import classNames from "classnames";
import { Wrapper } from "../Wrapper";
import { Typography } from "../Typography";
import { BannerProps } from "./types";
import "./styles.scss";

export const Banner = ({
  title,
  children,
  light,
  white,
  wrapperSize,
  customBgColor,
  xsmall,
  small,
  medium,
  big,
  backgroundImage,
  noPB,
  noPT,
  noCenter,
  customClass,
}: BannerProps) => {
  const classnames = classNames(`Banner`, {
    [`Banner--${customBgColor}`]: customBgColor,
    "Banner--light": light,
    "Banner--white": white,
    "Banner--big": big,
    "Banner--medium": medium,
    "Banner--small": small,
    "Banner--xsmall": xsmall,
    "Banner--noPB": noPB,
    "Banner--noPT": noPT,
    "Banner--noCenter": noCenter,
    [`${customClass}`]: customClass,
  });

  const InlineStylesBackgroundImage = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }
    : {};

  return (
    <div
      className={classnames}
      data-testid="Banner"
      style={InlineStylesBackgroundImage}>
      <Wrapper customClass={wrapperSize}>
        {title && (
          <div
            data-testid="Banner-title"
            className="Banner__title">
            <Typography Tag="h2">{title}</Typography>
          </div>
        )}
        {children}
      </Wrapper>
    </div>
  );
};
