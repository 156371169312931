import React, { useState } from "react";

import { Hero } from "../Hero";
import { Banner } from "@/components/Banner";
import { qrEditorModuleProps } from "@/data/types/home";

import { QREditor } from "@npm_leadtech/qr-editor";
import { QREditorSkeleton } from "../QREditorSkeleton";

import "./styles.scss";

export const QREditorHomeModule = ({ data }: { data: qrEditorModuleProps }) => {
  const { richtext, backgroundColor } = data;
  const [isQREditorLoaded, setIsQREditorLoaded] = useState(false);

  return (
    <Banner
      xsmall
      light
      noCenter
      customBgColor={backgroundColor}
      customClass="qr-editor__banner">
      <Hero heroText={richtext}>
        <div className="qr-editor__container">
          {!isQREditorLoaded && <QREditorSkeleton />}
          <div
            style={{
              display: isQREditorLoaded ? "block" : "none",
            }}>
            <QREditor
              onStylesLoaded={() => setIsQREditorLoaded(true)}
              theme={process.env.NEXT_PUBLIC_THEME}
              navigateToSubdomain={process.env.NEXT_PUBLIC_THEME !== "daikiri"}
              serviceConfig={{
                apiUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
                cloudinary: {
                  cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
                  uploadPreset: process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET,
                },
              }}
            />
          </div>
        </div>
      </Hero>
    </Banner>
  );
};
