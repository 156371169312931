"use client";
import React, { useState } from "react";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Typography } from "@/components/Typography";
import { NextImage } from "@/components/NextImage";

import { QR_PAGE_INDEX, updateIndex } from "@/utils/qrEditorNavigatorHelper";
import { generateQrDataClone, setNewQRData } from "@/utils/qrEditorHelper";

import { useToast } from "@/hooks/ui/useToast";

import { UserContext } from "@/contexts/UserContext";
import { QREditorContext } from "@/contexts/QREditorContext";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

import { PreviewProps } from "./types";

import "./styles.scss";

export const Preview = ({ items, dataQA, ctaStyle }: PreviewProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { toast } = useToast();
  const qrEditorContext = React.useContext(QREditorContext);
  const userContext = React.useContext(UserContext);
  const { setSource } = React.useContext(AmplitudeContext);

  // state to prevent select website as default type in qrEditorContext
  const [initialPreview, setInitialPreview] = React.useState(items[0].id);
  const [activePreview, setActivePreview] = useState(items[0].id);

  const [isQRSelected, setIsQRSelected] = React.useState(false);
  const [formURL, setFormURL] = React.useState("");

  const activePreviewItem = items.filter(
    (item) => item.id === initialPreview || item.id === activePreview
  );

  const qrTypeActive = `${activePreview === "links" ? "listoflinks" : activePreview}`;

  React.useEffect(() => {
    setIsQRSelected(false);
    qrEditorContext?.setEventSource(undefined);
    qrEditorContext?.setGridSelectEvent(false);
    setNewQRData(qrEditorContext, "");
  }, []);

  React.useEffect(() => {
    if (isQRSelected) {
      if (qrEditorContext?.qrData.currentPageIndex < QR_PAGE_INDEX.PAYMENT) {
        setSource("cta_buttons");
        qrEditorContext.setEventSource("cta_buttons");
        updateIndex({
          newIndex: qrEditorContext?.qrData.currentPageIndex + 1,
          qrEditorContext,
          userContext,
          toast,
          router,
        });

        router.push(formURL);
      }
    }
  }, [isQRSelected]);

  const handlePreviewButton = (url) => {
    const newQRData = generateQrDataClone(qrEditorContext, qrTypeActive);
    if (newQRData) newQRData.currentPageIndex = 0;
    setTimeout(() => {
      qrEditorContext?.setQrData(newQRData);
    }, 0);
    setFormURL(url);
    setIsQRSelected(true);
  };

  const updatePreview = (type) => {
    setActivePreview(type);
    setInitialPreview(undefined);
  };

  const displayQrTypeTitle = (item) => {
    const itemSelected = item.richtext[0];
    return (
      <Typography
        Tag={itemSelected.tag}
        size={itemSelected.size}
        spacing={itemSelected.spacing}>
        {itemSelected.content}
      </Typography>
    );
  };

  return (
    <div
      data-testid="Preview"
      className="Preview">
      <div className="Preview__buttons Preview__col qr-buttons">
        <div className="Preview__buttons__layout__grid">
          {items?.map((item) => {
            return (
              <Button
                dataQA={t(item.data_qa)}
                key={`preview-${item.id}`}
                bold
                icon={
                  <div className="button__icon">
                    <div>
                      <img
                        src={`../../svg/icon-qr-${item.id}.svg`}
                        alt={`QR ${item.id} icon`}
                        title={`QR ${item.id} icon`}
                      />
                    </div>
                  </div>
                }
                active={item.id === initialPreview || item.id === activePreview}
                onClick={() => updatePreview(item.id)}
                label={item.title}
                className="child qr-home-button"
              />
            );
          })}
        </div>
      </div>

      <div className="Preview__layout__template">
        <div className="Preview__contents  Preview__col">
          {activePreviewItem?.map((item, ind: number) => (
            <div
              key={ind}
              className="Preview__box u-borderRadius-medium">
              <div className="qrActive">{displayQrTypeTitle(item)}</div>
              <div className="Preview__image u-borderRadius-medium">
                <NextImage
                  src={`/images/phone-previews/phone-${item?.id}.png`}
                  alt={`QR ${item?.title} preview`}
                  width={265}
                  height={508}
                  title={`QR ${item?.title} preview`}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="Preview__contents  Preview__col">
          {activePreviewItem?.map((item, ind: number) => (
            <div
              className="Preview__content"
              key={ind}>
              {item.richtext.map((item, i: number) => (
                <Typography
                  key={i}
                  Tag={item.tag}
                  size={item.size}
                  spacing={item.spacing}>
                  {item.list
                    ? item.list.map((e, ind: number) => (
                        <li key={ind}>
                          {<span dangerouslySetInnerHTML={{ __html: e["item"] }}></span>}
                        </li>
                      ))
                    : item.content}
                </Typography>
              ))}

              <Button
                onClick={() => {
                  setSource("cta_buttons");
                  handlePreviewButton(`${item.ctaLink}/${qrTypeActive}`);
                }}
                label={item.ctaLabel}
                dataQA={dataQA}
                rounded={!!ctaStyle}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
